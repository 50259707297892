.home {
  background: white;
  max-width: 1080px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  img {
    min-width: 50%;
    width: 100%;
    max-width: 540px;
  }

  .nav {
    flex-direction: row;
    //flex-wrap: wrap;
    font-size: 18px;
    font-weight: bold;
    //margin: 20px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    a {
      text-decoration: none;
      color: #282c34;

      &:hover {
        color: #A21E21;
      }
    }

    .active {
      color: #A21E21;
    }
  }
}

.nav-link {
  color: black !important;
}

.card-header {
  cursor: pointer;
}

#linkedin {
  width: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

#media {
  width: 400px;
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
}

#dt {
  width: 200px;
  margin-top: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .home {
    max-width: 900px;
  }

  .nav > a {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  #linkedin, #media, #dt {
    margin-left: 20px;
  }
}
