.big {
  font-size: 24px;
  word-wrap: break-word;
}

.facts {
  text-align: left;

  bold {
    color: #A21E21;
    font-size: 24px;
    padding: 0;
    font-weight: bold;
  }
}

.sources {
  font-size: 10px;

  a {
    text-decoration: none;
    color: #0056b3;
  }
}

.blog-post {
  text-align: left;
  margin: 50px 100px;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    //align-items: center;

    div {
      //flex: 1;
      width: fit-content;
      min-width: 100px;
    }

    h1 {
      //flex: 1;
      font-size: 100px;
      //font-size: xxx-large;
      //font-family: Stowe, serif;

      //flex: 3;
      font-weight: bold;
      text-align: center;
      margin: 20px 10px 0 10px;
    }

    .flag {
      width: 100px;
    }

    img {
      width: 100px;
      height: auto;
      flex: 1;
    }
  }

  .quick-facts {
    display: flex;
    margin: 0;
    //background: #fafafa;
    justify-content: space-between;
    //align-items: center;

    .accordion {
      margin-top: 10px;
    }

    .card-header {
      background: #e5ece7;
      color: black;
      font-weight: bold;
    }

    & > div {
      margin-top: 10px;
    }

    & > div:first-child {
      width: 50%;
      margin-right: 10px;
      //position: relative;
      //flex: 1;
      //padding: 10px;
    }

    & > div:nth-child(2) {
      width: 50%;
      margin-left: 10px;
    }

    //a {
    //  color: #fafafa;
    //}
    //
    bold {
      font-weight: bold;
      color: #A21E21;
      font-size: 24px;
      //margin: 50px;
    }

    //
    div > img {
      border-radius: 10px;
      filter: drop-shadow(14px 14px 20px rgba(0, 0, 0, 0.25));
      //height: 100%;
    }

    //.fact {
    //  border: 1px solid black;
    //  //border-radius: 10px;
    //  cursor: pointer;
    //  margin: 25px 0;
    //  padding: 10px;
    //  transition: all 0.5s ease-in-out;
    //  width: fit-content;
    //  font-weight: bold;
    //
    //  &:hover {
    //    box-shadow: inset 400px 0 0 0 #a21e21;
    //    color: white;
    //  }
    //}

    //.overlay {
    //  position: absolute;
    //  background: rgba(255, 0, 0, 0.7);
    //  border-radius: 10px;
    //  color: white;
    //  height: calc(100% - 80px);
    //  width: calc(100% - 80px);
    //  top: 0;
    //  padding: 40px;
    //  justify-content: flex-end;
    //  //align-items: center;
    //  font-size: 18px;
    //  flex-direction: column;
    //
    //  b {
    //    color: white;
    //    font-size: 18px;
    //  }
    //}
  }
}

.pic-source {
  color: gray;
  font-size: 12px;
}

.btn {
  color: #fff;
  cursor: pointer;
  // display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  margin: 0 0 2em;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  //   @media(min-width: 400px) {
  //     display: inline-block;
  //     margin-right: 2.5em;

  //     &:nth-of-type(even) {
  //       margin-right: 0;
  //     }
  //   }

  @media(min-width: 600px) {

    margin: 0 1em 2em;

    //     &:nth-of-type(even) {
    //       margin-right: 2.5em;
    //     }

    //     &:nth-of-type(5) {
    //       margin-right: 0;
    //     }

  }

  &:hover {
    text-decoration: none;
  }

}


.btn-1 {
  background: darken(red, 1.5%);
  font-weight: 100;

  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btn-1:hover {
  background: rgba(red, 0);
  font-weight: 900;
  letter-spacing: 1px;

  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.footer-line {
  &-left {
    flex: 1;
    margin-right: 50px;
  }

  &-right {
    flex: 1;
    margin-left: 50px;
  }
}

#world {
  width: 50px;
}

@media (max-width: 900px) {
  .blog-post {
    .title {
      h1 {
        font-size: 48px;
      }
    }

    .quick-facts {
      flex-direction: column;

      .facts-menu {
        height: 200px;
      }

      & > div {
        width: 100%;
      }

      & > div:first-child, & > div:nth-child(2) {
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }

  #world {
    width: 20px;
  }

  .footer-line {
    &-left {
      margin-right: 20px;
    }

    &-right {
      margin-left: 20px;
    }
  }
}

@media (max-width: 780px) {
  .blog-post {
    margin: 0 50px;

    .title {
      //flex-direction: column-reverse;
      margin-bottom: 20px;

      div {
        margin: 10px;
        text-align: center;
      }

      h1 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 600px) {
  .pic-source {
    min-height: auto;
  }
}

