table, td {
  border: 1px solid black;
}

.facts-menu {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .menu-item {
    background: #e5ece7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: bold;

    &:hover {
      background: rgba(162, 30, 33, 1);
      color: white;
      cursor: pointer;
    }

    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
}

.overlay {
  position: absolute; /* Sit on top of the page content */
  visibility: hidden;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5ece7; /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  transition: all 0.3s;
  opacity: 0;
  padding: 10px 20px;
  overflow: scroll;

  h1 {
    font-size: 18px;
    color: #A21E21;
    font-weight: bold;
  }

  table {
    width: 100%;
  }
}

#exit {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.quick-value {
  color: #A21E21;
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .overlay {
    h1 {
      font-size: 14px;
    }
  }

  td {
    font-size: 10px;
  }

  #exit {
    width: 10px;
    height: 10px;
    top: 5px;
    right: 10px;
  }

  .quick-value {
    font-size: 12px;
  }
}
