button {
  background: #A21E21;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  margin: 10px;

  &:hover {
    background: #800c11;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}

input {
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
}

input[type=text] {
  border: 1px solid #a4a4a4;
}

table {
  margin-bottom: 10px;
}

td {
  padding: 5px;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  b {
    font-weight: bolder;
  }

  .facts {
    bold {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}
