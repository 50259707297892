@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    url: ($font-file + '.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Stowe', 'StoweOpenFace-d5jl.ttf');
@include font('Merriweather', 'Merriweather-Regular.ttf');

@import url('https://fonts.googleapis.com/css?family=Merriweather');
@import url('https://fonts.googleapis.com/css?family=Stowe');

body {
  margin: 0 !important;
  font-size: 1rem !important;
  font-family: Merriweather, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #A21E21 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: #A21E21;
  //font-weight: bold;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

#subscribe {
  text-decoration: none;
  background: #A21E21;
  padding: 10px 100px;
  color: white;
  transition: all 0.4s ease-in-out;
  font-weight: bold;

  &:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  }
}

.rotate-45 {
  transform: rotate(-45deg) translate(-140px, 60px);
  transform-origin: top left;
}
