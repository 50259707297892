.button {
  border-top: 1px solid black;
  //border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  margin: auto;
  overflow: hidden;
  //padding: 20px 10px;
  width: 50%;
  height: 240px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
}
